.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #0088ad;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-modal {
  max-width: 800px; /* Set your desired width */
  width: 90%; /* Adjust for responsiveness */
}


.routing-config-container {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  border-radius: 8px;
  background-color: #f4f4f4;
  text-align: left;
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.tabs button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: #ccc;
  border-radius: 5px;
}

.tabs button.active {
  background-color: #0088ad;
}

.add-config, .show-configs {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

input[type="text"] {
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button {
  padding: 10px 15px;
  background-color: #0088ad;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #4fa3d1;
}

button[type="button"] {
  background-color: #f0f0f0;
  color: #333;
}

button[type="button"]:hover {
  background-color: #ccc;
}

.routing-keys-tabs {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.routing-key-tab {
  padding: 10px; 
  margin: 5px;
  background-color: #ddd;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.routing-key-tab:hover {
  background-color: #0088ad;
}

.conditions{
  display: flex;
  justify-content: flex-start;
}

pre {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  overflow-x: auto;
  white-space: pre-wrap;
}


/* Manage Model */
.model-deployment {
  font-family: Arial, sans-serif;
  margin: 20px;
}

.title {
  font-size: 1.6rem;
  margin-bottom: 15px;
  color: #333;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

.loading-message {
  font-size: 1.1rem;
  color: #555;
}

.table-container {
  width: 100%;
  margin-top: 20px;
  overflow-x: auto;
}

.model-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
}

.model-table th,
.model-table td {
  padding: 8px 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 0.9rem;
  white-space: nowrap;  /* Prevent text from wrapping */
  overflow: hidden;     /* Hide overflowed text */
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
  height: 50px; /* Set a fixed row height */
}

.model-table th {
  background-color: #f0f0f0;
  font-weight: bold;
}

.action-buttons {
  display: flex;
  gap: 8px;
}

.action-button {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: background-color 0.3s;
}

.audit-button {
  background-color: #4CAF50;
  color: white;
}

.log-button {
  background-color: #2196F3;
  color: white;
}

.card-button {
  background-color: #FF9800;
  color: white;
}

.evaluation-button {
  background-color: #f44336;
  color: white;
}

.action-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.logs-container {
  margin-top: 30px;
}

.logs-table {
  width: 100%;
  border-collapse: collapse;
}

.logs-table th,
.logs-table td {
  padding: 8px 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 0.9rem;
}

.logs-table th {
  background-color: #f0f0f0;
  font-weight: bold;
}


/* routing model */

/* Container for the whole page */
.route-container {
  margin: 20px auto;
  max-width: 900px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Heading styles */
h2, h3 {
  color: #333;
  margin-bottom: 20px;
}

/* Form input fields */
.form-group {
  margin-bottom: 15px;
}

.form-control {
  padding: 10px;
  font-size: 16px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-control:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Button styling */
button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Table styling for deployed models */
.table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.table th, .table td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
}

.table th {
  background-color: #f2f2f2;
}

.table td {
  background-color: #fff;
}

/* Loading Spinner Styles */
.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.loading-indicator span {
  font-size: 18px;
  font-weight: bold;
  color: #007bff;
}

.spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #007bff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin-left: 10px;
}

/* Keyframe for spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Error message styling */
.error-message {
  margin-top: 20px;
  padding: 10px;
  background-color: #f8d7da;
  color: #721c24;
  border-radius: 5px;
  border: 1px solid #f5c6cb;
}

/* Status Container for deployment status */
.status-container {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #e7f7ff;
}

.status-container h3 {
  margin-bottom: 10px;
  color: #007bff;
}

.status-container p {
  font-size: 16px;
  color: #333;
}

/* Responsive Design */
@media (max-width: 768px) {
  .route-container {
    padding: 15px;
    margin: 10px;
  }

  .form-group {
    margin-bottom: 10px;
  }

  .form-control {
    font-size: 14px;
  }

  .table th, .table td {
    padding: 8px;
  }

  .spinner {
    width: 30px;
    height: 30px;
  }
}

/* Manage Pipeline */

/* main tab */

/* Container for the entire page */
.unique-tabs-page-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: #f4f4f9; /* Light background color */
}

/* Title of the page */
.unique-page-title {
  font-size: 30px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* Tab container - aligned horizontally */
.unique-tabs-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}

/* Individual tab style */
.unique-tab {
  flex: 1;
  padding: 18px;
  text-align: center;
  cursor: pointer;
  font-size: 18px;
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  transition: all 0.3s ease;
  margin-right: 10px;
  border-radius: 8px 8px 0 0;
}

.unique-tab:last-child {
  margin-right: 0;
}

.unique-tab:hover {
  background-color: #e0e0e0;
}

/* Active tab style */
.unique-active {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.3);
}

/* Tab content container */
.unique-tabs-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh; /* Adjusted height for better visibility */
  box-sizing: border-box;
}

/* Content for each tab */
.unique-tab-content {
  width: 100%;
  padding: 30px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-sizing: border-box;
  text-align: center;
  height: 100%;
}




/* clean upd Pipiline */
/* General Page Styles */
.unique-pipeline-cleanup-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  min-height: 100vh;
}

.unique-page-title {
  font-size: 28px;
  color: #333;
  text-align: center;
  margin-bottom: 25px;
  font-weight: bold;
}

/* Pipeline Card Styles */
.unique-pipeline-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.unique-pipeline-card {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  width: 350px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.unique-pipeline-card h3 {
  font-size: 16px;
  color: #222;
  word-wrap: break-word;
}

.unique-pipeline-details {
  font-size: 14px;
  color: #555;
  line-height: 1.5;
}

/* Buttons */
.unique-pipeline-buttons {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.unique-action-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.unique-delete-button {
  background-color: #e74c3c;
  color: white;
}

.unique-delete-button:hover {
  background-color: #c0392b;
}

.unique-details-button {
  background-color: #3498db;
  color: white;
}

.unique-details-button:hover {
  background-color: #2980b9;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Modal Content */
.modal-content {
  background-color: #fff;
  width: 80%;
  max-width: 900px; /* Increase modal size */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 80vh;
}

/* Modal Close Button */
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff5c5c;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}

/* Modal Title */
.modal-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}

/* Modal Details */
.modal-details {
  margin-bottom: 20px;
}

/* Individual Detail Item */
.detail-item {
  margin-bottom: 15px;
  font-size: 16px;
}

.detail-item p {
  margin: 8px 0;
}

.detail-item strong {
  color: #333;
}

/* Modal JSON Payload */
.modal-json-payload {
  background-color: #f8f8f8;
  padding: 15px;
  border-radius: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin-top: 10px;
  font-size: 14px;
  font-family: monospace;
  border: 1px solid #ddd;
  max-height: 400px;
  overflow-y: auto;
}

/* Copy Button */
.copy-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
  display: block;
  width: 100%;
  text-align: center;
}

.copy-btn:hover {
  background-color: #45a049;
}


/* Horizontal Line */
hr {
  border: 1px solid #ddd;
  margin-top: 20px;
  margin-bottom: 20px;
}


/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}



/* process manager */

.dashboard-pipelines-container {
  padding: 20px;
  background-color: #f9f9fc;
  font-family: Arial, sans-serif;
}

.dashboard-title {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.pipeline-card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
}

.pipeline-card {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 20px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.pipeline-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.pipeline-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.pipeline-card h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  color: #555;
}

.pipeline-card p {
  font-size: 16px;
  color: #666;
  margin: 8px 0;
}

.pipeline-card .badge {
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 12px;
}

.pipeline-card .badge-success {
  background-color: #28a745;
  color: #fff;
}

.pipeline-card .badge-warning {
  background-color: #ffc107;
  color: #fff;
}

.pipeline-card .badge-danger {
  background-color: #dc3545;
  color: #fff;
}

.pipeline-card .details-button {
  margin-top: 15px;
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.pipeline-card .details-button:hover {
  background-color: #0056b3;
}

.no-pipelines {
  font-size: 18px;
  color: #666;
  margin-top: 20px;
  text-align: center;
}

.job-table {
  width: 100%;
  border-collapse: collapse;
}

.job-table th,
.job-table td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
}

.job-table th {
  background: #f1f1f1;
  font-size: 14px;
  color: #444;
}

.job-table td {
  font-size: 14px;
  color: #555;
}


/* role information */
.accordion-details {
  padding: 20px;
  background-color: #f4f6f8;
}

.accordion-summary {
  background-color: #e3f2fd;
  font-weight: bold;
}
